import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { graphql, useStaticQuery } from 'gatsby'

const PageContact = props => { 
  const data = useStaticQuery(contactQuery)
  const { publicURL } = (data && data.file) || {}

  return (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Thank you for showing an interest in Happy Home Kindergarten School!'
        />
        <Divider />
        <img src={ publicURL } alt="" style={{ width:"100%" }}/>
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)}

export default PageContact


const contactQuery = graphql`
  query ContactQuery {
    file(absolutePath: { regex: "/contact.(jpeg|jpg|gif|png)/" }) {
      publicURL
    }
  }
`